body {
  /* always show scrollbar to avoid layout shift in Container after scrollbar showing (it's barely noticeable) */
  overflow-y: scroll;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.htmlContainer ul, .htmlContainer ol {
  margin-left: 16px;
}

.htmlContainer iframe {
  width: 100%;
}

.htmlContainer p {
  margin-bottom: 8px;
}

.htmlContainer h1 {
  font-size: 14px;
  font-weight: 700;
  color: '#002C3F';
  margin-bottom: 12px;
}

.htmlContainer video {
  height: auto !important;
}