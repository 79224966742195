.rdp-caption_label {
  color: #185ADB;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  background-color: #FFD5DB;
  color: #FF2C4B;
  font-weight: bold !important;
}

.rdp-caption_dropdowns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: normal;
}